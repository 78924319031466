import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";

import Token from "./utilities/jwt/Token.js";
import UsuarioService from "./service/user/user_service.js";

const routes = [
  {
    path: "/system",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/main/Home.vue"),
      },
      {
        path: "/gestao",
        name: "gestao",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/gestao/GestaoView.vue"),
      },
      {
        path: "/natureza-juridica",
        name: "netureza-juridica",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/natureza/NaturezaJuridicaView.vue"),
      },
      {
        path: "/estabelecimentos",
        name: "estabelecimentos",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/estabelecimento/EstabelecimentoView.vue"),
      },
      {
        path: "/cbo",
        name: "cbo",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/cbo/CboView.vue"),
      },
      {
        path: "/competencia",
        name: "competencia",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/competencias/CompetenciaView.vue"),
      },
      {
        path: "/lancamento",
        name: "lancamento",
        beforeEnter: verificarTermoUsuario,
        component: () =>
          import("./pages/lancamento/LancamentoView.vue"),
      },
      {
        path: "/log",
        name: "log",
        beforeEnter: verificarTermoUsuario,
        component: () =>
          import("./pages/log/LogView.vue"),
      },
      {
        path: "/groups",
        name: "groups",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/groups/GroupsView.vue"),
      },
      {
        path: "/users",
        name: "users",
        beforeEnter: verificarTermoUsuario,
        component: () => import("./pages/users/UsersView.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: () => import("./pages/main/Login.vue"),
  },
  {
    path: "/recoverPassword",
    name: "recoverPassword",
    component: () => import("./pages/users/UserRecoverPasswordView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("./pages/not_found/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


async function verificarTermoUsuario(to, from, next) {
  const tokenService = new Token();
  const usuarioService = new UsuarioService();

  try {
    await tokenService.checkToken();
    const usuario = await usuarioService.findById(tokenService.jwtDecode().id);

    if (usuario.termo === true) {
      next();
    } else {
      next('/');
    }
  } catch (error) {
    next('/');
  }
}

export default router;
