import Moment from 'moment';

export default class DateTime {
    formatarDateTime(date) {
        Moment.locale("pt-br");
        if (date) {
            return Moment(date).utc().format("DD/MM/YYYY h:mm:ss a");
        }
        return;
    }
    formatarDate(date) {
        Moment.locale("pt-br");
        if (date) {
            return Moment(date).utc().format("DD/MM/YYYY");
        }
        return;
    }
    formatarDateInput(date) {
        Moment.locale("pt-br");
        if (date) {
            return Moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
        }
        return;
    }
    formatarDateTime24hours(date) {
        Moment.locale("pt-br");
        if (date) {
            return Moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return;
    }

    formateToString(date) {
        Moment.locale("pt-br");
        if (date) {
            return Moment(date).utc().format("DD-MM-YYYY HH:mm:ss");
        }
        return;
    }

    formateMonthaToString(mes) {
        Moment.locale("pt-br");
        if (mes) {
            return Moment(mes).format("MMMM");
            /*return Moment([ano,mes]).format("MMMM/YYYY");*/
        }
        return;
    }

    isBetween(inicio, termino) {
        let atual = new Date().getTime();
        let dtInicio = new Date(inicio).getTime()
        let dtTermino = new Date(termino).getTime()

        if ((atual > dtInicio) && (dtTermino === 0)) {
            //console.log("ABERTO INDEFINIDADEMENTE")
            return { status: true, texto: "ABERTO, SEM PREVISÃO DE TÉRMINO"};
            //return true;
        }
        else if ((atual > dtInicio) && (atual < dtTermino)) {
            //console.log("ABERTO")
            return { status: true, texto: "ABERTO" };
            //return true;
        } else {
            //console.log("SOMENTE VISUALIZAÇÃO")
            return { status: false, texto: "SOMENTE VISUALIZAÇÃO" };
            //return false
        }
    }

    formatarDataHora(date) {
        Moment.locale("pt-br");
        if (date) {
            // Formata a data no formato desejado, incluindo dia, mês, ano, hora e minuto
            return Moment(date).format("YYYY-MM-DD HH:mm:ss");
        }
        return;
    }

}






