import { required } from "@vuelidate/validators";

export default class Grupo {
    constructor() {
        this.id = null;
        this.descricao = null;
        this.permissoes = [];
    }

    validations() {
        return {
            descricao: {
                required,
            },
            permissoes: {
                required,
            },
        };
    }
}