import { required, email, minLength } from "@vuelidate/validators";

import Grupo from './grupo';

export default class Usuario {
  constructor() {
    this.id = null;
    this.nome = null;
    this.contato = null;
    this.email = null;
    this.senha = null;
    this.status = true;
    this.termo = false;
    this.grupo = new Grupo();
    this.estabelecimento = [];
  }

  validationsCreate() {
    return {
      nome: {
        required,
      },
      contato: {
        required,
      },
      email: {
        required,
        email
      },
      senha: {
        required,
      },
    };
  }

  validations() {
    return {
      nome: {
        required,
      },
      contato: {
        required,
      },
      email: {
        required,
        email
      },
      senha: {
        minLength: minLength(6),
      },
      grupo: {
        id: {
          required,
        }
      },
      estabelecimento: {
        required,
      },
    };
  }
}
