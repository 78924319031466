import axios from "axios";

import Token from "../../utilities/jwt/Token.js";

import { gerarUrl } from "../../utilities/utils/Pageable";

export default class UsuarioService extends Token {
  searching(queryParams) {
    queryParams = gerarUrl(queryParams);
    return axios({
      method: "get",
      url: this.api_url + "usuario?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findById(id) {
    return axios({
      method: "get",
      url: this.api_url + "usuario/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(usuario) {
    return axios({
      method: "post",
      url: this.api_url + "usuario",
      withCredentials: true,
      data: usuario,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  createusuarioAndPassword(usuario) {
    return axios({
      method: "post",
      url: this.api_url + "usuario/create",
      data: usuario,
    });
  }

  update(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.id,
      withCredentials: true,
      data: usuario,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  resetPassword(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.id + "/resetar-password",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }
  recoverPassword(email, param) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + email + "/recuperar-password",
      data: param,
    });
  }

  activateRegistration(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.email + "/activate-registration",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  updatePassword(id, newPassword) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + id + "/alterar-password",
      withCredentials: true,
      data: newPassword,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  updateStatus(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.id + "/update-status",
      withCredentials: true,
      data: !usuario.status,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

  desabilitarUsuario(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.id + "/habilitar-desabilitar",
      withCredentials: true,
      data: !usuario.desabilitado,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

  aceitarTermo(usuario) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + usuario.id + "/aceitar-termos",
      withCredentials: true,
      data: usuario.termo,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

  atualizarUsuarios(id, status) {
    return axios({
      method: "put",
      url: this.api_url + "usuario/" + id + "/atualizar-usuarios",
      withCredentials: true,
      data: status,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

  updateStatusForLote(ids) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuario/atualizar-usuarios-lote',
      withCredentials: true,
      data: ids,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: "delete",
      url: this.api_url + "usuario/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }
}
